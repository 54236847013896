import React from "react"
import { StoryGroup } from "components"
import { flatStoryGroup } from "utils/array-util"

const LinkMap = {
  "JS Gelişimi": "ccec41fa2506",
  "JS Ekosistem": "ccec41fa2506",
  "JS Performance": "ccec41fa2506",
  "JS Platforms": "ccec41fa2506",
  "JS Enterprise": "ccec41fa2506",
  "JS Usage In Cloud": "ccec41fa2506",
  "JS Usage In SaaS": "ccec41fa2506",
  "JS Usage In API": "ccec41fa2506",
  "JS Usage In Data Analytics": "ccec41fa2506",

  Playgrounds: "34a8034a54c9",
  "Frontend Web Frameworks": "34a8034a54c9",
  "Data Layer Frameworks": "34a8034a54c9",
  "Dev Tools, Package": "34a8034a54c9",

  "Manager, Packing Tool": "34a8034a54c9",
  "Transpile, Compile, etc Utilities": "34a8034a54c9",
  "CSS Extensions,": "34a8034a54c9",
  "Component Explorers": "34a8034a54c9",
  "React UI Frameworks": "34a8034a54c9",
  Charts: "34a8034a54c9",
  "Utility Libraries": "34a8034a54c9",
  "Color Tools": "34a8034a54c9",

  "Test Tools": "34a8034a54c9",
  "Text Editors": "34a8034a54c9",
  "HTTP Request": "34a8034a54c9",
  "Extended Table": "34a8034a54c9",
  "JS Versions": "34a8034a54c9",
  "Design Tool": "34a8034a54c9",

  StateOfJS: "f307de2f5e6c",

  "Repo-Best Practice": "3d3941911dc4",
  "Repo-UI Components": "3d3941911dc4",
  "Repo-Charts": "3d3941911dc4",
  "Repo-Vis": "3d3941911dc4",
  "Repo-2D": "3d3941911dc4",
  "Repo-3D": "3d3941911dc4",
  "Repo-CSS Layout": "3d3941911dc4",
  "Repo-CSS Components": "3d3941911dc4",
  "Repo-Utility": "3d3941911dc4",
  "Repo-JS Transpiler": "3d3941911dc4",
  "Repo-Presentation": "3d3941911dc4",
  "Repo-Rendering Frameworks&Libs": "3d3941911dc4",
  "Repo Plugins": "3d3941911dc4",
  "Repo Fakes": "3d3941911dc4",

  "JS Flavors": "cb4f26d4e392",
  "JS Mobile&Desktop": "cb4f26d4e392",
  "JS FE Frameworks": "cb4f26d4e392",
  "JS Data Layers": "cb4f26d4e392",
  "JS Other Lib": "cb4f26d4e392",
  "JS BE Frameworks": "cb4f26d4e392",
  "JS Testing": "cb4f26d4e392",
  "JS Build Tools": "cb4f26d4e392",
  "JS Code Assist": "cb4f26d4e392",

  "Atomic Design": "704b0da2e7c9",
  "Component Driven Dev": "704b0da2e7c9",
  SPA: "704b0da2e7c9",
  JAMStack2: "704b0da2e7c9",
  "Component Explorer": "704b0da2e7c9",
  "Design System": "704b0da2e7c9",
  "Component Based Architecture": "704b0da2e7c9",

  "WebUI-Components": "b7698fc5131a",
  "WebUI-SPA": "b7698fc5131a",
  "WebUI-CBA": "b7698fc5131a",
  "WebUI-ArchitecturalPatterns": "b7698fc5131a",
  "WebUI-DataBindings": "b7698fc5131a",
  "WebUI-WebComponents&Polymer": "b7698fc5131a",
  "WebUI-DOM": "b7698fc5131a",
  "WebUI-Angular DOM": "b7698fc5131a",
  "WebUI-React/Vue VDOM": "b7698fc5131a",

  Standards: "2722c414afc1",
  "JS Library/Frameworks": "2722c414afc1",
  "Widget ve Chart Frameworks": "2722c414afc1",
  "CSS Frameworks": "2722c414afc1",

  "Adobe Air": "476412087091",
  "Google GWT": "476412087091",
  JavaFX: "476412087091",
  "Microsoft Silverlight": "476412087091",
  HTML5: "476412087091",

  Signal: "39b0b05b3c5c",
  Vite: "39b0b05b3c5c",
  RSC: "39b0b05b3c5c",
  Remix: "39b0b05b3c5c",
  Next: "39b0b05b3c5c",
  Turbo: "39b0b05b3c5c",

  RSC2: "227d48583069",
}

const storiesJS2024 = [
  {
    title: "July 2024 Frontend News",
    postId: "55659f8b9e69",
  },
  {
    title: "Observable Framework",
    postId: "70b602a50ba0",
  },
  {
    title: "Remix -> React Router 7",
    postId: "66f5be2ac256",
  },
  {
    title: "React 19 çıkışı neden ertelendi ?",
    postId: "4de514b4eefe",
  },
  {
    title: "React 19 ile gelen yenilikler",
    postId: "d548145ab16c",
  },
  {
    title: "2024 Frontend Neler Bekliyor ?",
    postId: "f47a6b22fa06",
  },
]

const storiesJS2023 = [
  {
    title: "Kasım 2023 Frontend News-1",
    postId: "f47a6b22fa06",
  },
  {
    title: "Kasım 2023 Frontend News-1",
    postId: "53201b2968ba",
  },
  {
    title: "React Ekosistemi",
    postId: "23ef21c7071c",
  },
  {
    title: "Next.js React’ın Geleceğine Mi Karar Veriyor",
    postId: "160f712cc589",
  },
  {
    title: "Next ve Remix Arasındaki Kapışma",
    postId: "92e1e3d97f6e",
  },
  {
    title: "React Ekosisteminde Next.JS’in Yeri",
    postId: "e6f404498a0b",
  },
  {
    title: "Eylül 2023 Frontend News-1",
    postId: "48a007028452",
  },
  {
    title: "Local-First Web App Development",
    postId: "590cde19299d",
  },
  {
    title: "Agustos 2023 Frontend News-1",
    postId: "d43cd324f9e0",
  },
  {
    title: "Agustos 2023 Frontend News-2",
    postId: "cbf37473c191",
  },
  {
    title: "Agustos 2023 Frontend News-3",
    postId: "e4ab399abab4",
  },
  {
    title: "Dan Abramov Meta Ekibinden Ayrıldı",
    postId: "903751f4a7bd",
  },
  {
    title: "RSC'nin Ekosistemdeki Yeri",
    postId: "227d48583069",
  },
  {
    title: "FE Ecosystem News May 2023",
    postId: "70ddbbdbeb4",
  },
  {
    title: "React Documentary Series -1",
    postId: "df36ec9fdad9",
  },
  {
    title: "React Documentary Series -2",
    postId: "54f694b40a03",
  },
  {
    title: "React Documentary Series -3",
    postId: "ca4e680c1362",
  },
  {
    title: "Create React App Neden Sonlandırıldı ?",
    postId: "76edd054e9c",
  },
  {
    title: "React Ekosisteminde Çekişmeler ve Yeni Yaklaşımlar",
    postId: "39b0b05b3c5c",
  },
  {
    title: "Frontend Ekosisteminde Takip Ettiğim Kişiler",
    postId: "eec3973d88ab",
  },
  {
    title: "RSC Frontend Geliştiricilerini Nasıl Etkileyecek ?",
    postId: "6357924b1125",
  },
]

const storiesJS2022 = [
  {
    title: "2022 ES13 ile JavaScript'e Eklenen Özellikler",
    postId: "25b6b9c2ecab",
  },
  {
    title: "2022 JavaScript'in Durumu",
    postId: "a6e31adb4ad0",
  },
  {
    title: "2022'de JAMStack Geliştirme Topluluğu Araştırması",
    postId: "f733914db9e",
  },
  {
    title: "2022 May-Aug News-1",
    postId: "a4d170560506",
  },
  {
    title: "2022 May-Aug News-2",
    postId: "db5bde79e12",
  },
  {
    title: "2022 May-Aug News-3",
    postId: "db5bde79e12",
  },
  {
    title: "2022 Apr News",
    postId: "2fffb3fb4f14",
  },
]

const storiesJS2021 = [
  {
    title: "2021 ES12 ile JavaScript'e Eklenen Özellikler",
    postId: "772c910f152c",
  },
  {
    title: "2021 Geleceğin Programlama Dili JS Mi ?",
    postId: "ccec41fa2506",
  },
  {
    title: "2021 GitHub’da En Yüksek 50 JS Reposu",
    postId: "3d3941911dc4",
  },
  {
    title: "2021'de Frontend Alanında Öne Çıkan Kavramlar",
    postId: "ba562c99c305",
  },
  {
    title: "2021'de Anlamakta Zorlandığım Kavramlar",
    postId: "2926c21166ab",
  },
]

const storiesJS2020 = [
  {
    title: "2020 ES11 ile JavaScript'e Eklenen Özellikler",
    postId: "f68e9ef7f65b",
  },
  {
    title: "2020 JS Kütüphaneler Periyodik Cetveli",
    postId: "cb4f26d4e392",
  },
]

const storiesJS2019 = [
  {
    title: "2019 Frontend Araçları, Kütüphaneleri",
    postId: "34a8034a54c9",
  },
  {
    title: "2019 Bileşen Odaklı UI(Kullanıcı Arayüzü) Geliştirme",
    postId: "704b0da2e7c9",
  },
]

const storiesJS2018 = [
  {
    title: "2018 Javascript Durumu ve Geleceği",
    postId: "f307de2f5e6c",
  },
]

const storiesJS2017 = [
  {
    title: "2017 (Web UI Components)",
    postId: "b7698fc5131a",
  },
  {
    title: "2017 Frontend Geliştirme Trendleri",
    postId: "2722c414afc1",
  },
]

const storiesJS2009 = [
  {
    title: "2009'da Zengin İçerikli İnternet Uygulamaları",
    postId: "476412087091",
  },
]

const storiesGroup = [
  {
    title: "2024",
    storyPart: storiesJS2024,
  },
  {
    title: "2023",
    storyPart: storiesJS2023,
  },
  {
    title: "2022",
    storyPart: storiesJS2022,
  },
  {
    title: "2021",
    storyPart: storiesJS2021,
  },
  {
    title: "2020",
    storyPart: storiesJS2020,
  },
  {
    title: "2019",
    storyPart: storiesJS2019,
  },
  {
    title: "2018",
    storyPart: storiesJS2018,
  },
  {
    title: "2017",
    storyPart: storiesJS2017,
  },
  {
    title: "2009",
    storyPart: storiesJS2009,
  },
]

export const context = {
  stories: flatStoryGroup(storiesGroup),
  linkMap: LinkMap,
  title: "Frontend Ekosistem",
  path: "frontend-ecosystem",
}

const FrontendEcosytemPage = () => {
  return (
    <StoryGroup
      title={context.title}
      complex={true}
      stories={storiesGroup}
      linkMap={context.linkMap}
    />
  )
}

export default FrontendEcosytemPage
